import { inject } from 'inversify';
import {
  action,
  autorun,
  computed,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import type { User } from 'entities/User';
import { AimsLkApi } from 'shared/api/AimsLkApi';

export class AuthStore {
  private static localStorageKey = 'token';

  @observable
  token: string | null = null;

  @observable
  loggedUser: User | null = null;

  private readonly apiClient: AimsLkApi;

  constructor(@inject(AimsLkApi) apiClient: AimsLkApi) {
    this.apiClient = apiClient;
    makeAutoObservable(this);
  }

  initialize() {
    runInAction(() => {
      this.token = localStorage.getItem(AuthStore.localStorageKey);
    });
    this.apiClient.setToken(this.token);

    // пока не сохраняем disposer никуда, потому что стор глобальный
    reaction(
      () => this.token,
      (token) => {
        if (token !== null) {
          localStorage.setItem(AuthStore.localStorageKey, token);
        } else {
          localStorage.removeItem(AuthStore.localStorageKey);
        }
      },
    );

    // пробрасываем изначальное значение и все изменения токена в apiClient
    autorun(() => {
      this.apiClient.setToken(this.token);
    });
  }

  @action
  setToken(token: string) {
    this.token = token;
  }

  @action
  clearToken() {
    this.token = null;
  }

  @action
  setLoggedUser(loggedUser: User) {
    this.loggedUser = loggedUser;
  }

  @computed
  get isLogged(): boolean {
    return this.token !== null;
  }
}
