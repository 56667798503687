import { ApiResponse } from 'entities/ApiResponse';
import { AuthToken } from 'entities/AuthToken';
import { UnifiedDto } from 'entities/UnifiedDto';
import { checkOtherErrors } from 'shared/api/ApiError';
import { checkProblemError } from 'shared/api/Problem';
import { checkNotNull } from 'shared/utils/Utils';

export class AimsLkApi {
  private domain = 'https://api.aimsclinical.ru';
  private token: string | null = null;

  setToken(token: string | null) {
    this.token = token;
  }

  async loginWithOtp(email: string): Promise<void> {
    const response = await fetch(`${this.domain}/api/v1/loginWithOtp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    await this.handleResponse(response);
  }

  async confirmLogin(email: string, code: string): Promise<string> {
    const response = await fetch(`${this.domain}/api/v1/loginWithOtp/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        code: code,
      }),
    });
    await this.handleResponse(response);
    return await response.text();
  }

  async registerWithOtp(email: string): Promise<void> {
    const response = await fetch(`${this.domain}/api/v1/registerWithOtp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    await this.handleResponse(response);
  }

  async confirmRegister(email: string, code: string): Promise<string> {
    const response = await fetch(`${this.domain}/api/v1/registerWithOtp/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        code: code,
      }),
    });
    await this.handleResponse(response);
    return await response.text();
  }

  async fetchAuthToken(username: string, password: string): Promise<ApiResponse<AuthToken>> {
    const response = await fetch(
      `${this.domain}/api/v1/auth?username=${username}&password=${password}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    await this.handleResponse(response);
    return await response.json();
  }

  async getUserInfo(): Promise<ApiResponse<UnifiedDto>> {
    const token = checkNotNull(this.token);
    const response = await fetch(`${this.domain}/api/v1/users/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await this.handleResponse(response);
    return await response.json();
  }

  async getMyAppointments(): Promise<ApiResponse<UnifiedDto>> {
    const token = checkNotNull(this.token);
    const response = await fetch(`${this.domain}/api/v1/appointments`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await this.handleResponse(response);
    return await response.json();
  }

  async getAppointmentProtocol(id: string): Promise<ApiResponse<UnifiedDto>> {
    const token = checkNotNull(this.token);
    const response = await fetch(
      `${this.domain}/api/v1/appointment-protocol?appointment_id=${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    await this.handleResponse(response);
    return await response.json();
  }

  async deleteAppointment(id: string): Promise<ApiResponse<null>> {
    const token = checkNotNull(this.token);
    const response = await fetch(`${this.domain}/api/v1/appointment/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await this.handleResponse(response);
    return await response.json();
  }

  private async handleResponse(response: Response): Promise<void> {
    await checkProblemError(response);
    checkOtherErrors(response);
  }
}
