import { Toaster } from '@gravity-ui/uikit';
import { Container } from 'inversify';
import { Router } from 'react-router-dom';

import { createRouter } from 'app/app-router/router';
import { AuthStore } from 'features/auth/AuthStore';
import { AimsLkApi } from 'shared/api/AimsLkApi';

export function createAppContainer() {
  const container = new Container({ autoBindInjectable: true });
  container.bind(Router).toConstantValue(createRouter());
  container.bind(Toaster).toSelf().inSingletonScope();
  container.bind(AimsLkApi).toSelf().inSingletonScope();
  container.bind(AuthStore).toSelf().inSingletonScope();
  return container;
}

export const appContainer = createAppContainer();
