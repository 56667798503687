import React, { RefObject, useEffect, useRef } from 'react';

import { Button, Flex, PinInput, PinInputApi, Text, TextInput, spacing } from '@gravity-ui/uikit';
import { observer } from 'mobx-react-lite';

import { LoginPageModel } from 'pages/login/LoginPageModel';
import { useInject } from 'shared/utils/hooks/useInject';

export const LoginPage = observer(() => {
  const model = useInject(LoginPageModel);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const codeInputRef = useRef<PinInputApi>(null);

  useEffect(() => {
    switch (model.step) {
      case LoginPageModel.ENTER_EMAIL_STEP:
        emailInputRef.current?.focus();
        break;
      case LoginPageModel.ENTER_CODE_STEP:
        codeInputRef.current?.focus();
        break;
    }
  }, [model.step]);

  return (
    <Flex centerContent width="100%" minHeight="100%">
      <Flex centerContent direction="column" width="100%" maxWidth="250px" as="form">
        {model.step === LoginPageModel.ENTER_EMAIL_STEP && (
          <>
            <Text className={spacing({ mb: 4 })} variant={'header-1'}>
              Вход в Личный Кабинет
            </Text>
            <TextInput
              controlRef={emailInputRef}
              type="text"
              placeholder={'Email'}
              value={model.email}
              // не разрешаем изменять поле когда спрашиваем
              disabled={model.showCreateAccountButtons}
              onUpdate={(username) => model.setEmail(username)}
            />
            {model.formError !== null && (
              <Text color={'danger'} className={spacing({ my: 1 })}>
                {model.formError}
              </Text>
            )}

            {!model.showCreateAccountButtons && (
              <>
                <Button
                  view="action"
                  type="submit"
                  className={spacing({ mt: 2 })}
                  onClick={() => model.clickSendButton()}
                  loading={model.loading}
                  disabled={model.email.length === 0}
                >
                  Отправить код
                </Button>
              </>
            )}

            {model.showCreateAccountButtons && (
              <>
                <Text color={'info'} className={spacing({ my: 1 })} style={{ textAlign: 'center' }}>
                  Указанный email не зарегистрирован. Создать аккаунт?
                </Text>
                <Flex gap={2}>
                  <Button
                    view="normal"
                    className={spacing({ mt: 2 })}
                    onClick={() => model.createAccountClicked()}
                    loading={model.loading}
                    disabled={model.email.length === 0}
                  >
                    Создать аккаунт
                  </Button>
                  <Button
                    view="outlined"
                    className={spacing({ mt: 2 })}
                    onClick={() => model.cancelCreateAccountClicked()}
                    disabled={model.loading}
                  >
                    Отмена
                  </Button>
                </Flex>
              </>
            )}
          </>
        )}
        {model.step === LoginPageModel.ENTER_CODE_STEP && (
          <>
            <Text className={spacing({ mb: 3 })} variant={'header-1'}>
              Введите код
            </Text>
            <Text className={spacing({ mb: 1 })} variant={'body-1'} color={'secondary'}>
              {model.email}
            </Text>
            <PinInput
              apiRef={codeInputRef as RefObject<PinInputApi>}
              length={LoginPageModel.CODE_SIZE}
              className={spacing({ my: 2 })}
              size={'l'}
              value={model.code}
              onUpdate={(code) => model.setCode(code)}
            />
            {model.formError !== null && (
              <Text color={'danger'} className={spacing({ mb: 2 })}>
                {model.formError}
              </Text>
            )}
            <Button
              view="action"
              onClick={() => model.confirmCode()}
              loading={model.loading}
              disabled={
                model.code.filter((char) => char.length > 0).length !== LoginPageModel.CODE_SIZE
              }
            >
              Отправить код
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
});
