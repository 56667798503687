import { ToastProps, Toaster, ToasterPublicMethods } from '@gravity-ui/uikit';
import { inject } from 'inversify';

export class ToasterService implements ToasterPublicMethods {
  private readonly toaster: Toaster;

  constructor(@inject(Toaster) toaster: Toaster) {
    this.toaster = toaster;
  }

  add(toast: ToastProps): void {
    this.toaster.add(toast);
  }

  has(toastName: ToastProps['name']): boolean {
    return this.toaster.has(toastName) ?? false;
  }

  remove(toastName: ToastProps['name']): void {
    this.toaster.remove(toastName);
  }

  removeAll(): void {
    this.toaster.removeAll();
  }

  update(toastName: ToastProps['name'], override: Partial<ToastProps>): void {
    this.toaster.update(toastName, override);
  }
}
