import React, { ReactElement } from 'react';

import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { AuthStore } from 'features/auth/AuthStore';
import { useInject } from 'shared/utils/hooks/useInject';

/**
 * Редиректим уже залогиненых пользователей сразу в ЛК
 */
export const RedirectToAccount = observer((props: { children: ReactElement }): ReactElement => {
  const authStore = useInject(AuthStore);
  return authStore.isLogged ? <Navigate to="/appointments" /> : props.children;
});
