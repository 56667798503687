export class ProblemError extends Error {
  readonly type: string;
  readonly title: string;

  constructor(type: string, title: string) {
    super();
    this.type = type;
    this.title = title;
  }
}

export async function checkProblemError(response: Response) {
  if (response.headers.get('Content-Type') === 'application/problem+json') {
    const problemObject = await response.json();
    throw new ProblemError(problemObject.type, problemObject.title);
  }
}
