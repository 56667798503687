import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import { ProtectedRoute } from 'app/app-router/ProtectedRoute';
import { RedirectToAccount } from 'app/app-router/RedirectToAccount';
import { AppointmentPage } from 'pages/appointment/AppointmentPage';
import { AppointmentsPage } from 'pages/appointments/AppointmentsPage';
import { ErrorPage } from 'pages/error/ErrorPage';
import { LoginPage } from 'pages/login/LoginPage';

export const createRouter = () =>
  createBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: (
            <RedirectToAccount>
              <LoginPage />
            </RedirectToAccount>
          ),
        },
        {
          path: 'appointments',
          element: (
            <ProtectedRoute>
              <AppointmentsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'appointment/:id',
          element: (
            <ProtectedRoute>
              <AppointmentPage />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ]);
