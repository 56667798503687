import { ReactElement, ReactNode } from 'react';

import 'widgets/content-column/ContentColumn.css';

export function ContentColumn(props: { children: ReactNode }): ReactElement {
  return (
    <div className="content-column">
      <div className="content-column__container">{props.children}</div>
    </div>
  );
}
