import { ReactElement, useEffect, useState } from 'react';

import { Button, spacing } from '@gravity-ui/uikit';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Appointment } from 'entities/Appointment';
import { AuthStore } from 'features/auth/AuthStore';
import { AppointmentListItem } from 'pages/appointments/AppointmentListItem/AppointmentListItem';
import { AimsLkApi } from 'shared/api/AimsLkApi';
import { error, sortedByDescending } from 'shared/utils/Utils';
import { useInject } from 'shared/utils/hooks/useInject';
import { ContentColumn } from 'widgets/content-column/ContentColumn';
import { PageHeader } from 'widgets/page-header/PageHeader';

export function AppointmentsPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const authStore = useInject(AuthStore);
  const api = useInject(AimsLkApi);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const dto = await api.getMyAppointments();
      if (dto.type !== 'success') error();
      const sortedAppointments = sortedByDescending(dto.payload.appointments ?? [], (it) => it.id);
      setAppointments(sortedAppointments);
      setLoading(false);
    })();
  }, [api]);

  const onClickLogout = () => {
    authStore.clearToken();
  };

  return (
    <ContentColumn>
      <PageHeader>Список приемов</PageHeader>
      {loading ? (
        <CircularProgress />
      ) : (
        appointments.map((appointment) => {
          return (
            <AppointmentListItem
              key={appointment.id}
              appointment={appointment}
              onClick={() => navigate(`/appointment/${appointment.id}`)}
            />
          );
        })
      )}
      <Button view={'normal'} className={spacing({ my: 2 })} onClick={onClickLogout}>
        Выйти из аккаунта
      </Button>
    </ContentColumn>
  );
}
