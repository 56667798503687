import { useMemo } from 'react';

import { Appointment } from 'entities/Appointment';

import 'pages/appointments/AppointmentListItem/AppointmentListItem.css';

export function AppointmentListItem(props: { appointment: Appointment; onClick: () => void }) {
  const { appointment, onClick } = props;
  const createTime = useMemo(() => {
    const date = new Date(appointment.create_time);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }, [appointment.create_time]);
  return (
    <div key={appointment.id} className="appointment-list-item" onClick={onClick}>
      <span className="appointment-list-item__title">Прием #{appointment.id}</span>
      <span className="appointment-list-item__date">{createTime}</span>
      <span className="appointment-list-item__status">{appointment.status}</span>
    </div>
  );
}
