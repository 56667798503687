import { ReactElement, useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { AppointmentProtocol } from 'entities/AppointmentProtocol';
import { AppointmentResource } from 'entities/AppointmentResource';
import { FormattedProtocol } from 'entities/FormattedProtocol';
import { AimsLkApi } from 'shared/api/AimsLkApi';
import { checkNotNull, error, sortedBy } from 'shared/utils/Utils';
import { useInject } from 'shared/utils/hooks/useInject';
import { ContentColumn } from 'widgets/content-column/ContentColumn';
import { PageHeader } from 'widgets/page-header/PageHeader';

import 'pages/appointment/AppointmentPage.css';

export function AppointmentPage(): ReactElement {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [protocol, setProtocol] = useState<AppointmentProtocol | null>(null);
  const [resources, setResources] = useState<AppointmentResource[]>([]);
  const [formattedProtocol, setFormattedProtocol] = useState<FormattedProtocol | null>(null);
  const api = useInject(AimsLkApi);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const dto = await api.getAppointmentProtocol(checkNotNull(id));
      if (dto.type !== 'success') error();
      const protocol1 = checkNotNull(dto.payload.appointment_protocols)[0];
      setProtocol(protocol1);
      const formattedProtocol1 = checkNotNull(dto.payload.formatted_protocols)[0];
      setFormattedProtocol(formattedProtocol1);
      setLoading(false);
      const sortedResources = sortedBy(dto.payload.appointment_resources ?? [], (it) => it.order);
      setResources(sortedResources);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTime = useMemo(() => {
    if (protocol === null) return '';
    const date = new Date(protocol.create_time);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }, [protocol]);

  const onClickDelete = () => {
    if (!id) return;
    const confirmed = confirm(
      'Вы действительно хотите удалить данный прием и все дочерние сущности (протокол, аудио-файлы)?',
    );
    if (confirmed) {
      (async () => {
        await api.deleteAppointment(id);
        navigate('/appointments');
      })();
    }
  };

  // const parsedColumns = useMemo(() => {
  //   if (protocol == null) return [];
  //   let parsedObject: { [key: string]: string } | null = null;
  //   try {
  //     parsedObject = JSON.parse(protocol.summary);
  //   } catch (error) {
  //     // Не удалось спарсить JSON, возможно там не джсон
  //   }
  //   if (parsedObject == null) return [];
  //
  //   const keys = Object.keys(parsedObject);
  //   keys.sort();
  //   return keys.map((key) => ({
  //     name: key,
  //     content: checkNotNull(parsedObject)[key] ?? '',
  //   }));
  // }, [protocol]);

  return (
    <ContentColumn>
      <PageHeader>Прием #{id}</PageHeader>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="appointment-page__create-time">Протокол создан {createTime}</div>
          {formattedProtocol && <HandledProtocol protocol={formattedProtocol} />}
          <h2 className="appointment-page__subheader">Исходные файлы</h2>
          {resources.map((resource) => {
            return <ResourcePlayer key={resource.id} resource={resource} />;
          })}
          <DebugInformation protocol={protocol} onClickDelete={onClickDelete} />
        </>
      )}
    </ContentColumn>
  );
}

function HandledProtocol(props: { protocol: FormattedProtocol }): ReactElement {
  const [[clickedGroupIndex, clickedColumnIndex], setClickedIndex] = useState<[number, number]>([
    -1, -1,
  ]);
  const rowClicked = (groupIndex: number, columnIndex: number) => {
    const entry = checkNotNull(props.protocol.groups[groupIndex].entries[columnIndex]);
    // noinspection JSIgnoredPromiseFromCall
    navigator.clipboard.writeText(entry.value);
    setClickedIndex([groupIndex, columnIndex]);
    setTimeout(() => {
      setClickedIndex([-1, -1]);
    }, 500);
  };
  return (
    <>
      <h3 className="appointment-page__protocol-header">Направление: {props.protocol.header}</h3>
      {props.protocol.groups.map((group, groupIndex) => {
        return (
          <div key={group.name} className="appointment-page__group">
            <h4 className="appointment-page__group-header">{group.name}</h4>
            {group.entries.map((entry, columnIndex) => {
              return (
                <div
                  key={entry.title}
                  className={`appointment-page__column ${clickedGroupIndex === groupIndex && clickedColumnIndex === columnIndex ? 'appointment-page__column_clicked' : ''}`}
                  onClick={() => rowClicked(groupIndex, columnIndex)}
                  onKeyDown={() => rowClicked(groupIndex, columnIndex)}
                  role={'presentation'}
                >
                  <div className="appointment-page__column-background" />
                  <div className="appointment-page__column-name">{entry.title}</div>
                  <div className="appointment-page__column-value">{entry.value}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DebugInformation(props: {
  protocol: AppointmentProtocol | null;
  onClickDelete: () => void;
}): ReactElement {
  const [extrasVisible, setExtrasVisible] = useState(false);
  return (
    <>
      <div
        className="appointment-page__show-extra-button"
        onClick={() => setExtrasVisible(!extrasVisible)}
      >
        {extrasVisible ? 'Скрыть дополнительную информацию' : 'Показать дополнительную информацию'}
      </div>
      {extrasVisible && (
        <div>
          {props.protocol && (
            <>
              <h2>Результат транскрибации</h2>
              <div>{props.protocol.transcribed_content}</div>
            </>
          )}
          <button className="appointment-page__delete-button" onClick={props.onClickDelete}>
            Удалить прием
          </button>
          {/*<h2>Summary</h2>*/}
          {/*<div>{props.protocol.summary}</div>*/}
        </div>
      )}
    </>
  );
}

function ResourcePlayer(props: { resource: AppointmentResource }): ReactElement {
  return (
    <div className={'appointment-page__audio'}>
      <figure>
        <audio controls src={props.resource.url} className={'appointment-page__audio-controls'} />
      </figure>
    </div>
  );
}
