import { inject } from 'inversify';

import { ApiError } from 'shared/api/ApiError';
import { ProblemError } from 'shared/api/Problem';
import { ErrorReporter } from 'shared/error-reporter/ErrorReporter';
import { ToasterService } from 'shared/toaster/ToasterService';

export class HandleNetworkErrorUseCase {
  private readonly toasterService: ToasterService;
  private readonly errorReporter: ErrorReporter;

  constructor(
    @inject(ToasterService) toasterService: ToasterService,
    @inject(ErrorReporter) errorReporter: ErrorReporter,
  ) {
    this.toasterService = toasterService;
    this.errorReporter = errorReporter;
  }

  invoke(error: unknown) {
    if (error instanceof ProblemError) {
      this.toasterService.add({
        name: error.type,
        title: error.title,
        theme: 'warning',
      });
    } else if (error instanceof ApiError) {
      this.toasterService.add({
        name: 'api-error',
        title: error.message,
        theme: 'danger',
      });
    } else {
      const errorDescription = String(error);
      this.toasterService.add({
        name: `unknown-error:${errorDescription}`,
        title: 'Неизвестная ошибка',
        content: errorDescription,
        theme: 'danger',
      });
      this.errorReporter.report(error);
    }
  }
}
