import React from 'react';

import { Router, RouterProvider } from 'react-router-dom';
import 'reflect-metadata';

import { RouterType } from 'app/app-router/RouterType';
import { AppInitializer } from 'features/app-initializer/AppInitializer';
import { ToasterServiceProvider } from 'shared/toaster/ToasterServiceProvider';
import { useInject } from 'shared/utils/hooks/useInject';
import { AppThemeProvider } from 'widgets/app-theme-provider/AppThemeProvider';

import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';
import 'app/app/global-styles/globals.scss';
import 'app/app/global-styles/variables.scss';

export function App() {
  const router = useInject(Router) as RouterType;

  return (
    <AppThemeProvider>
      <ToasterServiceProvider>
        <AppInitializer>
          <RouterProvider router={router} />
        </AppInitializer>
      </ToasterServiceProvider>
    </AppThemeProvider>
  );
}
