import { inject } from 'inversify';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { AuthStore } from 'features/auth/AuthStore';
import { HandleNetworkErrorUseCase } from 'features/network/HandleApiErrorUseCase';
import { AimsLkApi } from 'shared/api/AimsLkApi';
import { ProblemError } from 'shared/api/Problem';

export class LoginPageModel {
  static CODE_SIZE = 6;
  static ENTER_EMAIL_STEP = 0;
  static ENTER_CODE_STEP = 1;
  static EMAIL_NOT_REGISTERED_PROBLEM = 'auth/emailNotRegistered';

  @observable
  step = LoginPageModel.ENTER_EMAIL_STEP;

  @observable
  email = '';

  @observable
  code: string[] = [];

  @observable
  loading = false;

  @observable
  formError = '';

  @observable
  showCreateAccountButtons = false;

  private readonly api: AimsLkApi;
  private readonly authStore: AuthStore;
  private readonly handleNetworkErrorUseCase: HandleNetworkErrorUseCase;

  constructor(
    @inject(AimsLkApi) api: AimsLkApi,
    @inject(AuthStore) authStore: AuthStore,
    @inject(HandleNetworkErrorUseCase) handleNetworkErrorUseCase: HandleNetworkErrorUseCase,
  ) {
    this.api = api;
    this.authStore = authStore;
    this.handleNetworkErrorUseCase = handleNetworkErrorUseCase;
    makeAutoObservable(this);
  }

  @action
  setEmail(email: string) {
    this.email = email;
  }

  @action
  setCode(code: string[]) {
    this.code = code;
    this.formError = '';
    const codeSize = this.code.filter((charString) => charString.length > 0).length;
    if (codeSize === LoginPageModel.CODE_SIZE) {
      this.confirmCode();
    }
  }

  @action
  async clickSendButton() {
    this.loading = true;
    this.formError = '';

    try {
      await this.api.loginWithOtp(this.email);
      runInAction(() => {
        this.step = LoginPageModel.ENTER_CODE_STEP;
      });
    } catch (error) {
      if (error instanceof ProblemError) {
        if (error.type === LoginPageModel.EMAIL_NOT_REGISTERED_PROBLEM) {
          runInAction(() => {
            this.showCreateAccountButtons = true;
          });
        } else {
          runInAction(() => {
            this.formError = error.title;
          });
        }
      } else {
        this.handleNetworkErrorUseCase.invoke(error);
      }
    }
    runInAction(() => {
      this.loading = false;
    });
  }

  @action
  async createAccountClicked() {
    this.loading = true;
    this.formError = '';

    try {
      await this.api.registerWithOtp(this.email);
      runInAction(() => {
        this.step = LoginPageModel.ENTER_CODE_STEP;
      });
    } catch (error) {
      if (error instanceof ProblemError) {
        runInAction(() => {
          this.formError = error.title;
        });
      } else {
        this.handleNetworkErrorUseCase.invoke(error);
      }
    }
    runInAction(() => {
      this.loading = false;
    });
  }

  @action
  cancelCreateAccountClicked() {
    this.showCreateAccountButtons = false;
  }

  @action
  async confirmCode() {
    this.loading = true;
    this.formError = '';
    try {
      let token: string;
      if (this.showCreateAccountButtons) {
        token = await this.api.confirmRegister(this.email, this.code.join(''));
      } else {
        token = await this.api.confirmLogin(this.email, this.code.join(''));
      }
      this.authStore.setToken(token);
    } catch (error) {
      if (error instanceof ProblemError) {
        runInAction(() => {
          this.formError = error.title;
        });
      } else {
        this.handleNetworkErrorUseCase.invoke(error);
      }
    }
    runInAction(() => {
      this.loading = false;
    });
  }
}
