import { FC, ReactNode } from 'react';

import { Flex, Loader } from '@gravity-ui/uikit';
import { inject } from 'inversify';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'features/auth/AuthStore';
import { HandleNetworkErrorUseCase } from 'features/network/HandleApiErrorUseCase';
import { AimsLkApi } from 'shared/api/AimsLkApi';
import { checkNotNull, error } from 'shared/utils/Utils';
import { useInject } from 'shared/utils/hooks/useInject';

class AppInitializerModel {
  @observable
  initialized = false;

  private readonly apiClient: AimsLkApi;
  private readonly authStore: AuthStore;
  private readonly handleNetworkErrorUseCase: HandleNetworkErrorUseCase;

  constructor(
    @inject(AimsLkApi) sarcApiClient: AimsLkApi,
    @inject(AuthStore) authStore: AuthStore,
    @inject(HandleNetworkErrorUseCase) handleNetworkErrorUseCase: HandleNetworkErrorUseCase,
  ) {
    this.apiClient = sarcApiClient;
    this.authStore = authStore;
    this.handleNetworkErrorUseCase = handleNetworkErrorUseCase;
    makeAutoObservable(this);
    this.initialize();
  }

  private async initialize(): Promise<void> {
    this.authStore.initialize();
    if (this.authStore.isLogged) {
      try {
        const userInfo = await this.apiClient.getUserInfo();
        if (userInfo.type !== 'success') error();
        const loggedUser = checkNotNull(userInfo.payload.users)[0];
        this.authStore.setLoggedUser(loggedUser);
      } catch (err) {
        this.handleNetworkErrorUseCase.invoke(err);
      }
    }
    runInAction(() => {
      this.initialized = true;
    });
  }
}

export const AppInitializer: FC<{ children: ReactNode }> = observer((props) => {
  const initializerModel = useInject(AppInitializerModel);
  if (!initializerModel.initialized) {
    return (
      <Flex centerContent width="100%" minHeight="100%">
        <Loader size="l" />
      </Flex>
    );
  } else {
    return props.children;
  }
});
