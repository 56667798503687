import { ReactElement } from 'react';

import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { AuthStore } from 'features/auth/AuthStore';
import { useInject } from 'shared/utils/hooks/useInject';

/**
 * Редиректим уже гостей на страницу входа
 */
export const ProtectedRoute = observer((props: { children: ReactElement }): ReactElement => {
  const authStore = useInject(AuthStore);
  return authStore.isLogged ? props.children : <Navigate to="/" />;
});
