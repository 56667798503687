import { FC, ReactNode } from 'react';

import { Toaster, ToasterComponent, ToasterProvider } from '@gravity-ui/uikit';

import { useInject } from 'shared/utils/hooks/useInject';

export const ToasterServiceProvider: FC<{ children: ReactNode }> = (props) => {
  const toaster = useInject(Toaster);
  return (
    <ToasterProvider toaster={toaster}>
      <ToasterComponent />
      {props.children}
    </ToasterProvider>
  );
};
